
export default {
	name: "CoreAnchorLinkNavigation",
	props: {
		settings: {
			type: Object,
			default: () => {},
		},
		dataSite: {
			type: Object,
			default: () => {},
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedIndex: 0,
			offset: 0,
			isFixed: false,
		};
	},
	computed: {
		links() {
			return this.settings.anchorLinks;
		},
		isTop() {
			return this.settings?.variants?.isTop !== undefined
				? this.settings?.variants?.isTop
				: true;
		},
	},
	mounted() {
		this.updateOffset();
		window.addEventListener("scroll", this.updateOffset);
	},
	destroyed() {
		window.removeEventListener("scroll", this.updateOffset);
	},
	methods: {
		setSelectedIndex(value) {
			this.selectedIndex = value;
			const id = this.links[value]?.section;
			if (id) {
				const el = document.getElementById(id);
				el.scrollIntoView({ behavior: "smooth" });
			}
		},
		isSelectedIndex(value) {
			return this.selectedIndex === value ? true : false;
		},
		updateOffset() {
			if (this.isEditable) {
				return;
			}
			const top = document
				.getElementById(this.settings.id)
				.getBoundingClientRect().top;
			this.offset = 0;
			if (this.isTop) {
				const mainNav = document.getElementById("main-nav");
				if (mainNav) {
					const navHeight = mainNav.getBoundingClientRect().height;
					this.offset = navHeight;
					if (top <= navHeight) {
						this.isFixed = true;
					} else {
						this.isFixed = false;
					}
				} else {
					if (top <= 0) {
						this.isFixed = true;
					} else {
						this.isFixed = false;
					}
				}
			} else {
				const top = document
					.getElementById(this.settings.id)
					.getBoundingClientRect().top;
				const height = document
					.getElementById(this.settings.id)
					.getBoundingClientRect().height;

				const windowHeight = window.innerHeight;

				if (top + height <= windowHeight) {
					this.isFixed = true;
				} else {
					this.isFixed = false;
				}
			}
		},
	},
};
